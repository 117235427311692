import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import CategoriesFilter from '../components/CategoriesFilter';
import ItemList from '../components/ItemList';
import UnderConstruction from '../components/UnderConstruction';

export default function HomePage({ data }) {
  const category = null;
  const items = data.items.nodes;

  return (
    <>
      <SEO title="Home" />
      <UnderConstruction />
      {/* <CategoriesFilter activeCategory={category} />
      <ItemList items={items} /> */}
    </>
  );
}

export const query = graphql`
  query HomePageItems {
    items: allSanityItem(
      sort: {
        fields: [categories___sortIndex, name]
        order: [ASC, ASC]
      }
      filter: { public: { eq: true } }
    ) {
      nodes {
        name
        id
        slug {
          current
        }
        categories {
          id
          name
        }
        image {
          asset {
            fluid(maxWidth: 325, maxHeight: 325) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
