import React from 'react';

export default function UnderConstruction() {
  return (
    <center style={{ margin: '20rem 0 20rem 0' }}>
      <p>
        Anna Linnea is taking a break while we make some exciting
        changes.
      </p>
      Follow us on Instagram
      <a
        href="https://instagram.com/annalinneadesigns"
        alt="Anna Linnea Designs"
        target="_blank"
        rel="noreferrer"
        style={{ paddingLeft: '10px' }}
      >
        @annalinneadesigns
      </a>{' '}
      for updates!
    </center>
  );
}
